import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { IntlProvider } from 'react-intl'
import messages from '../data/messages'

const TemplateWrapper = ({ children }) => {
  const { title, description, languages } = useSiteMetadata()
  const url = typeof window !== `undefined` ? window.location.pathname : '/zh/'
  const { langs, defaultLangKey } = languages
  const currentlangKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${currentlangKey}/`
  const langUrl = getUrlForLang(homeLink, url)
  const langsMenu = getLangs(langs, currentlangKey, langUrl)

  return (
    <IntlProvider locale={currentlangKey} messages={messages[currentlangKey]}>
      <div>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix('/')}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix('/')}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix('/')}img/og-image.jpg`}
          />
        </Helmet>
        <Navbar langs={langsMenu} currentLangkey={currentlangKey} />
        <div>{children}</div>
        <Footer locale={currentlangKey} />
      </div>
    </IntlProvider>
  )
}
export default TemplateWrapper
