import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'
import { injectIntl, FormattedMessage } from 'react-intl'

const Footer = class extends React.Component {
  render() {
    const { locale } = this.props
    const localizedPath = '/' + locale + '/'
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img src={logo} alt="CGFCC" style={{ height: '5em' }} />
          <div className="is-size-3">
            <FormattedMessage id="site.title" />
          </div>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to={localizedPath} className="navbar-item">
                        <FormattedMessage id="footer.home" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={localizedPath + 'about'}
                      >
                        <FormattedMessage id="footer.about" />
                      </Link>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="footer.admin" />
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to={localizedPath + 'blog'}>
                        <FormattedMessage id="footer.latest" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to={localizedPath + 'contact'}
                      >
                        <FormattedMessage id="footer.contact" />
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default injectIntl(Footer)
