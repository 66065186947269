import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'
import SelectLanguage from './SelectLanguage'
import { injectIntl, FormattedMessage } from 'react-intl'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    const locale = this.props.currentLangkey

    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to={'/' + locale + '/'} title="Logo">
              <div style={{ marginTop: '3.5px', marginBottom: '3.5px' }}>
                <img src={logo} alt="CGFCC" style={{ height: '45px' }} />
                <span className="is-size-4">
                  {' '}
                  <FormattedMessage id="site.title" />
                </span>
              </div>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to={`/${locale}/about`}>
                <FormattedMessage id="navbar.about" />
              </Link>
              <Link className="navbar-item" to={`/${locale}/gallery`}>
                <FormattedMessage id="navbar.gallery" />
              </Link>
              <Link className="navbar-item" to={`/${locale}/blog`}>
                <FormattedMessage id="navbar.blog" />
              </Link>
              <Link className="navbar-item" to={`/${locale}/contact`}>
                <FormattedMessage id="navbar.contact" />
              </Link>
            </div>
            {/* replaced github icon, use it later for language toggle */}

            <SelectLanguage langs={this.props.langs}/>
          </div>
        </div>
      </nav>
    )
  }
}

export default injectIntl(Navbar)
