export default {
  zh: {
    'site.title': '加拿大甘肃同乡会',
    'navbar.about': '关于我们',
    'navbar.gallery': '照片集锦',
    'navbar.blog': '最新动态',
    'navbar.contact': '联系我们',
    'footer.home': '首页',
    'footer.about': '关于我们',
    'footer.admin': '管理网页',
    'footer.latest': '最新动态',
    'footer.contact': '联系我们',
    'home.readMore': '阅读更多',
    'blog.keepReading': '阅读全文 →',
    'tags.browseAll': '查看所有标签',
  },
  en: {
    'site.title': 'GCAC',
    'navbar.about': 'About',
    'navbar.gallery': 'Gallery',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contact',
    'footer.home': 'Home',
    'footer.about': 'About',
    'footer.admin': 'Admin',
    'footer.latest': 'Latest Stories',
    'footer.contact': 'Contact',
    'home.readMore': 'Read more',
    'blog.keepReading': 'Keep Reading →',
    'tags.browseAll': 'Browse all tags',
  },
}
