import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const SelectLanguage = (props) => {
  const langs = props.langs

  return (
    <div className="languageSelect">
      <Link
        className="languageColumn"
        to={langs[0].link}
        key={langs[0].langKey}
      >
        <div selected={langs[0].selected}>{langs[0].langKey}</div>
      </Link>
      <span className="languageColumn">/</span>
      <Link
        className="languageColumn"
        to={langs[1].link}
        key={langs[1].langKey}
      >
        <div selected={langs[1].selected}>{langs[1].langKey}</div>
      </Link>
    </div>
  )
}

SelectLanguage.propTypes = {
  langs: PropTypes.array,
}

export default SelectLanguage
